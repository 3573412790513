import { useEffect, useState } from "react";

import "./DiaryTimeCard.css";
import Images from "./diaryTimeCards/Images";
import Productivity from "./diaryTimeCards/Productivity";
import DefaultWebcam from "../../../images/noCamshot.jpg"
import DefaultScreenshot from "../../../images/noScreenshot.jpg"
import TimeCardModal from "./TimeCardModal";
import Backdrop from "../../ui/Backdrop";
import { fetchImageFromAPI } from "../../api/apiCalls";
import { baseURL, getTrackingDataScreenshots, getTrackingDataWebcam } from "../../api/env";
import LoadingSpinner from "../UiElements/LoadingSpinner";
import { Tag } from 'primereact/tag';
import { ReactSession } from 'react-client-session'
import { FaInfoCircle } from "react-icons/fa";

function DiaryTimeCard(props) {
  const user = ReactSession.get("User")
  const [timeCardModalIsOpen, setTimeCardModalIsOpen] = useState(false);
  const [timeCardIsSelected, setTimeCardIsSelected] = useState(false);
  const [screenshot, setScreenshot] = useState();
  const [webcam, setWebcam] = useState();
  const status = ['Productive', 'Idle', 'Overtime', 'Manual Time', 'Marked', 'OT Discard', 'Rejected'];
  const [loading, setLoading] = useState(true)

  function openCardDetails() {
    setTimeCardModalIsOpen(true);
  }

  function closeModalHandler() {
    setTimeCardModalIsOpen(false);
  }

  function ToggleTimeCard(e) {
    setTimeCardIsSelected(!timeCardIsSelected);
    props.handleSelection(e.target.checked, e.target.id)
  }

  const fetchImages = async(props) => {
    let scshot = props.screensrc === 'default' || props.screensrc === undefined ? DefaultScreenshot: await fetchImageFromAPI(`${baseURL}${getTrackingDataScreenshots}?ImagePath=screenshots/` + props.screensrc + ".jpg")
    let wcam = props.camsrc === 'default' || props.camsrc === undefined ? DefaultWebcam: await fetchImageFromAPI(`${baseURL}${getTrackingDataWebcam}?ImagePath=webcam/`+ props.camsrc + ".jpg")
    setScreenshot(scshot)
    setWebcam(wcam)
  }

  useEffect(() => {
    const fetchData = async() => {
      try {
        await fetchImages(props)
        setLoading(false)
      } catch (error) {
        console.error("ERROR: ", error)
      }
    }
    
    fetchData()
  }, [props])

  let productivity = status[props.status];

  return (
    <>
      {!loading ? (
        <>
          {user.role == 2 ?(
              <div className="diaryTimeCard">
                <div className="diaryTimeCard-Actions" style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr',alignItems:'center', justifyContent:'space-between'}}>
                  {props.status === 6 ? (
                    <>
                      <div><FaInfoCircle title="You cannot update a Rejected timecard, please reachout to your manager"/></div>
                      <div className="activityTime">{props.activitytime}</div>
                      {props.complianceFlag && props.complianceFlag !== 'Geofence' && (
                        <Tag severity={"warning"}>Compliance</Tag>
                      )}
                      {props.complianceFlag && props.complianceFlag === 'Geofence' && (
                        <Tag severity={"warning"}>Geofence</Tag>
                      )}
                    </>
                  ): (
                    <>
                      <input style={{justifySelf:'flex-start'}} type="checkbox" className="cards-checkboxes" onChange={ToggleTimeCard} id={props.id}/>
                      <div className="activityTime">{props.activitytime}</div>
                      {props.complianceFlag && props.complianceFlag !== 'Geofence' && (
                        <Tag severity={"warning"}>Compliance</Tag>
                      )}
                      {props.complianceFlag && props.complianceFlag === 'Geofence' && (
                        <Tag severity={"warning"}>Geofence</Tag>
                      )}
                    </>
                  )}
                </div>
                <Productivity
                  project={props.workDescription}
                  mouse={props.productivityMouse}
                  keys={props.productivityKeys}
                  productivityType={productivity}
                />
                <div className="diary-shots" onClick={openCardDetails}>
                  <Images imageSRC={screenshot} />
                  <Images imageSRC={webcam} />
                </div>

                {timeCardModalIsOpen && (
                  <TimeCardModal
                    activityTime={props.activitytime}
                    project={props.workDescription}
                    mouse={props.productivityMouse}
                    keys={props.productivityKeys}
                    productivityType={productivity}
                    screensrc={props.screensrc}
                    camsrc={props.camsrc}
                    onCancel={closeModalHandler}
                  />
                )}
                {timeCardModalIsOpen && <Backdrop onCancel={closeModalHandler} />}
              </div>
          ) : (
            <div className="diaryTimeCard">
              <div className="diaryTimeCard-Actions" style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr',alignItems:'center', justifyContent:'space-between'}}>
                <input style={{justifySelf:'flex-start'}} type="checkbox" className="cards-checkboxes" onChange={ToggleTimeCard} id={props.id}/>
                <div className="activityTime">{props.activitytime}</div>
                {props.complianceFlag && props.complianceFlag !== 'Geofence' && (
                  <Tag severity={"warning"}>Compliance</Tag>
                )}
                {props.complianceFlag && props.complianceFlag === 'Geofence' && (
                  <Tag severity={"warning"}>Geofence</Tag>
                )}
              </div>
              <Productivity
                project={props.workDescription}
                mouse={props.productivityMouse}
                keys={props.productivityKeys}
                productivityType={productivity}
              />
              <div className="diary-shots" onClick={openCardDetails}>
                <Images imageSRC={screenshot} />
                <Images imageSRC={webcam} />
              </div>

              {timeCardModalIsOpen && (
                <TimeCardModal
                  activityTime={props.activitytime}
                  project={props.workDescription}
                  mouse={props.productivityMouse}
                  keys={props.productivityKeys}
                  productivityType={productivity}
                  screensrc={props.screensrc}
                  camsrc={props.camsrc}
                  onCancel={closeModalHandler}
                />
              )}
              {timeCardModalIsOpen && <Backdrop onCancel={closeModalHandler} />}
            </div>
          )}
        </>
      ) : (
        <LoadingSpinner/>
      )}
    </>
  );
}

export default DiaryTimeCard;
